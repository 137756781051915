export enum App {
  Marketing = "contractor-marketing-app",
}

export enum AsyncStorageKey {
  Legal = "legal",
  Template = "template",
}

export enum BucketName {
  Public = "assets.blueumbrella.consulting",
  Dev = "dev.blueumbrella.consulting",
  Prod = "prod.blueumbrella.consulting",
}

export enum CustomerStatus {
  B2B = "b2b",
  Customer = "customer",
  Potential = "potential",
}

export enum MemberType {
  Business = "business",
  Customer = "customer",
}

export enum NotificationStatus {
  Sent = "sent",
  Unsent = "unsent",
}

export enum ProjectEventStatus {
  Done = "done",
  InProgress = "inprogress",
  Issue = "issue",
  OnHold = "onhold",
  Pending = "pending",
  Skipped = "skipped",
}

export enum ProjectStatus {
  Active = "active",
  Archived = "archived",
  Hold = "hold",
  Stopped = "stopped",
}

export enum ResourceId {
  Business = "Business",
  Channel = "channel",
  Customer = "customer",
  Member = "member",
  Notification = "notification",
  Project = "project",
  Service = "search",
  Review = "review",
  Template = "template",
}

export enum ToggleResource {
  AdvancedTheme = "advanced-theme",
  CustomerModal = "customer-modal",
  ProjectModal = "project-modal",
}
