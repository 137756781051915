/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const customer = createReducer(initialState.customer, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_CUSTOMER_SUCCESS),
      (state: IRootState["customer"], action: AnyAction) => ({
        ...state,
        details: action.customer,
      })
    )
    .addCase(
      createAction(types.DELETE_CUSTOMER_SUCCESS),
      (state: IRootState["customer"], action: AnyAction) => ({
        ...state,
        details: action.customer,
      })
    )
    .addCase(
      createAction(types.GET_CUSTOMER_SUCCESS),
      (state: IRootState["customer"], action: AnyAction) => ({
        ...state,
        details: action.customer,
      })
    )
    .addCase(
      createAction(types.SEARCH_CUSTOMERS_SUCCESS),
      (state: IRootState["customer"], action: AnyAction) => ({
        ...state,
        list: action.results,
      })
    )
    .addCase(
      createAction(types.TOGGLE_CUSTOMER_MODAL),
      (state: IRootState["customer"], action: AnyAction) => ({
        ...state,
        details: action.customer,
      })
    )
    .addCase(
      createAction(types.UPDATE_CUSTOMER_SUCCESS),
      (state: IRootState["customer"], action: AnyAction) => ({
        ...state,
        details: action.customer,
      })
    );
});

export default customer;
