/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const business = createReducer(initialState.business, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_BUSINESS_SUCCESS),
      (state: IRootState["business"], action: AnyAction) => ({
        ...state,
        details: action.business,
      })
    )
    .addCase(
      createAction(types.DELETE_BUSINESS_SUCCESS),
      (state: IRootState["business"], action: AnyAction) => ({
        ...state,
        details: action.business,
      })
    )
    .addCase(
      createAction(types.GET_BUSINESS_SUCCESS),
      (state: IRootState["business"], action: AnyAction) => ({
        ...state,
        details: action.business,
      })
    )
    .addCase(
      createAction(types.SEARCH_BUSINESSES_SUCCESS),
      (state: IRootState["business"], action: AnyAction) => ({
        ...state,
        list: action.results,
      })
    )
    .addCase(
      createAction(types.UPDATE_BUSINESS_SUCCESS),
      (state: IRootState["business"], action: AnyAction) => ({
        ...state,
        details: action.business,
      })
    );
});

export default business;
