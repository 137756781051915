import React from "react";

/** Vendors */
import { ConfigProvider } from "antd";

/** Themes */
import { theme1, theme2, theme3, theme4, theme5, theme6 } from "./theme";

function SectionalConfig({
  children,
  version,
}: {
  children: React.ReactElement;
  version?: 1 | 2 | 3 | 4 | 5 | 6;
}) {
  let theme: any = {};
  if (version === 1) {
    theme = theme1;
  } else if (version === 2) {
    theme = theme2;
  } else if (version === 3) {
    theme = theme3;
  } else if (version === 4) {
    theme = theme4;
  } else if (version === 5) {
    theme = theme5;
  } else if (version === 6) {
    theme = theme6;
  }

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
}

export default SectionalConfig;
