/** Vendors */
import _ from "lodash";

const _baseline = {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Layout: {
      headerHeight: 100,
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/menu/style/index.ts */
    Menu: {
      darkItemBg: "transparent",
    },
  },
  token: {
    borderRadius: 4,
    fontFamily: "Poppins",
  },
};

export const theme1 = _.merge(_baseline, {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts */
    Button: {
      defaultBg: "#d9d9d9",
      defaultColor: "#353535",
      primaryBg: "#3C6E71",
      primaryColor: "#ffffff",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Layout: {
      headerBg: "#0c1617",
    },
  },
  inherit: false,
  /** @link https://github.com/ant-design/ant-design/blob/master/components/theme/interface/seeds.ts */
  token: {
    colorBgBase: "#d9d9d9",
    colorLink: "#3C6E71",
    colorPrimary: "#3C6E71",
    colorTextBase: "#0c1617",
  },
});

export const theme2 = _.merge(_baseline, {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts */
    Button: {
      defaultBg: "#d9d9d9",
      defaultColor: "#353535",
      primaryBg: "#3C6E71",
      primaryColor: "#ffffff",
    },
    Layout: {
      bodyBg: "#d9d9d9",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Typography: {
      colorTextHeading: "#0c1617",
    },
  },
  /** @link https://github.com/ant-design/ant-design/blob/master/components/theme/interface/seeds.ts */
  token: {
    colorBgBase: "#d9d9d9",
    colorLink: "#3C6E71",
    colorPrimary: "#3C6E71",
    colorTextBase: "#0c1617",
  },
});

export const theme3 = _.merge(_baseline, {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts */
    Button: {
      defaultBg: "#d9d9d9",
      defaultColor: "#353535",
      primaryBg: "#3C6E71",
      primaryColor: "#ffffff",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Layout: {
      headerBg: "#0c1617",
    },
  },
  /** @link https://github.com/ant-design/ant-design/blob/master/components/theme/interface/seeds.ts */
  token: {
    colorBgBase: "#0c1617",
    colorLink: "#d9d9d9",
    colorPrimary: "#3C6E71",
    colorTextBase: "#ffffff",
  },
});

export const theme4 = _.merge(_baseline, {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts */
    Button: {
      defaultBg: "#d9d9d9",
      defaultColor: "#353535",
      primaryBg: "#3C6E71",
      primaryColor: "#ffffff",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Layout: {
      headerBg: "#0c1617",
    },
  },
  /** @link https://github.com/ant-design/ant-design/blob/master/components/theme/interface/seeds.ts */
  token: {
    colorBgBase: "#0c1617",
    colorLink: "#d9d9d9",
    colorPrimary: "#3C6E71",
    colorTextBase: "#ffffff",
  },
});

export const theme5 = _.merge(_baseline, {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts */
    Button: {
      defaultBg: "#d9d9d9",
      defaultColor: "#353535",
      primaryBg: "#3C6E71",
      primaryColor: "#ffffff",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Layout: {
      headerBg: "#0c1617",
    },
  },
  /** @link https://github.com/ant-design/ant-design/blob/master/components/theme/interface/seeds.ts */
  token: {
    colorBgBase: "#0c1617",
    colorLink: "#d9d9d9",
    colorPrimary: "#3C6E71",
    colorTextBase: "#ffffff",
  },
});

export const theme6 = _.merge(_baseline, {
  components: {
    /** @link https://github.com/ant-design/ant-design/blob/master/components/button/style/index.ts */
    Button: {
      defaultBg: "#f9f9f9",
      defaultColor: "#353535",
      primaryBg: "#3C6E71",
      primaryColor: "#ffffff",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/card/style/index.ts */
    Card: {
      colorBgContainer: "#ffffff",
    },
    Checkbox: {
      colorBorder: "#3C6E71",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/collapse/style/index.ts */
    Collapse: {
      contentBg: "#0c1617",
      headerBg: "#0c1617",
    },
    /** @link c */
    Form: {
      labelColor: "#d9d9d9",
    },
    Input: {
      addonBg: "#f9f9f9",
      colorBgContainer: "#f9f9f9",
      colorText: "#0c1617",
      colorTextPlaceholder: "#0c161755",
    },
    /** @link https://github.com/ant-design/ant-design/blob/master/components/layout/style/index.ts */
    Layout: {
      bodyBg: "#0c1617",
      headerBg: "#0c1617",
      footerBg: "#0c1617",
    },
    Select: {
      colorBgElevated: "#f9f9f9",
      colorText: "#0c1617",
      colorTextPlaceholder: "#0c161755",
      colorTextQuaternary: "#0c161755",
      multipleItemBg: "#3C6E71",
      selectorBg: "#ffffff",
    },
    Tooltip: {
      colorBgDefault: "#3C6E71",
    },
    Typography: {
      colorTextHeading: "#ffffff",
    },
  },
  /** @link https://github.com/ant-design/ant-design/blob/master/components/theme/interface/seeds.ts */
  token: {
    colorBgBase: "#0c1617",
    colorLink: "#d9d9d9",
    colorPrimary: "#3C6E71",
    colorTextBase: "#ffffff",
  },
});
