/** Vendors */
import axios from "axios";

/** Types */
import type {
  ICustomer,
  ICustomerPayload,
  ICustomerResponse,
  ISearchCustomersPayload,
  ISearchCustomersResponse,
} from "../types";

/* Customer API Endpoints */
export async function createCustomerApi(
  props: Partial<ICustomer>
): Promise<ICustomerResponse> {
  return await axios.put<ICustomerPayload, ICustomerResponse>(
    "/customer",
    props
  );
}

export async function deleteCustomerApi(
  props: ICustomerPayload
): Promise<ICustomerResponse> {
  return await axios.delete<ICustomerPayload, ICustomerResponse>("/customer", {
    data: props,
  });
}

export async function getCustomerApi(
  params: Partial<ICustomer>
): Promise<ICustomerResponse> {
  return await axios.get<ICustomerPayload, ICustomerResponse>("/customer", {
    params,
  });
}

export async function searchCustomersApi(
  params: ISearchCustomeresPayload
): Promise<ISearchCustomeresResponse> {
  return await axios.get<ISearchCustomeresPayload, ISearchCustomeresResponse>(
    "/customer/search",
    {
      params,
    }
  );
}

export async function updateCustomerApi(
  props: ICustomerPayload
): Promise<ICustomerResponse> {
  return await axios.post<ICustomerPayload, ICustomerResponse>("/customer", {
    data: props,
  });
}

const customer = {
  create: {
    customer: createCustomerApi,
  },
  delete: {
    customer: deleteCustomerApi,
  },
  get: {
    customer: getCustomerApi,
  },
  search: {
    customers: searchCustomersApi,
  },
  update: {
    customer: updateCustomerApi,
  },
};

export default customer;
