/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "@dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const notification = createReducer(initialState.notification, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) =>
        redux.addresource(state, action, ResourceId.Notification)
    )
    .addCase(
      createAction(types.DELETE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) =>
        redux.removeMatchCase(state, action, ResourceId.Notification)
    )
    .addCase(
      createAction(types.GET_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => ({
        ...state,
        details: action.notification,
      })
    )
    .addCase(
      createAction(types.SEARCH_NOTIFICATIONS_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) =>
        redux.joinOrOverwrite(state, action)
    )
    .addCase(
      createAction(types.UPDATE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) =>
        redux.updateMatchCase(state, action, ResourceId.Notification)
    );

  /** Notification Channel(s) */
  builder
    .addCase(
      createAction(types.CREATE_NOTIFICATION_CHANNEL_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => ({
        ...state,
        channel: redux.addResource(state, action, ResourceId.Channel),
      })
    )
    .addCase(
      createAction(types.DELETE_NOTIFICATION_CHANNEL_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => ({
        ...state,
        channel: redux.removeMatchCase(state, action, ResourceId.Channel),
      })
    )
    .addCase(
      createAction(types.GET_NOTIFICATION_CHANNEL_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => ({
        ...state,
        channel: { ...state, details: action.channel },
      })
    )
    .addCase(
      createAction(types.SEARCH_NOTIFICATION_CHANNELS_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => ({
        ...state,
        channel: redux.joinOrOverwrite(state, action),
      })
    )
    .addCase(
      createAction(types.UPDATE_NOTIFICATION_CHANNEL_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => ({
        ...state,
        channel: redux.updateMatchCase(state, action, ResourceId.Channel),
      })
    )
    .addDefaultCase((state) => state);
});

export default notification;
