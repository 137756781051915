import React from "react";

/** Vendors */
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import ReactDOM, { createRoot } from "react-dom/client";

/** Redux */
import store from "./redux/configureStore";

/** Custom Components */
import App from "./App";

/** Custom Setup */
import "@dist/js/setup";

/** Custom Icons */
import "./dist/js/icons";

/** CSS */
import "./dist/css/app.css";
import "./dist/css/colors.css";
import "./dist/css/format.css";

const root = document.getElementById("root");

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <Auth0Provider
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_REACT_APP_PUBLIC_URL,
      }}
      clientId={import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID}
      domain={import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN}
    >
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </Auth0Provider>
  </React.StrictMode>
);
