/** Vendors */
import axios from "axios";

/** Types */
import type {
  INotification,
  INotificationPayload,
  INotificationResponse,
  ISearchNotificationsPayload,
  ISearchNotificationsResponse,
  /** Notification Channel(s) */
  INotificationChannel,
  INotificationChannelPayload,
  INotificationChannelResponse,
  ISearchNotificationChannelsPayload,
  ISearchNotificationChannelsResponse,
} from "../types";

/* Notification API Endpoints */
async function createNotificationApi(
  props: Partial<INotification>
): Promise<INotificationResponse> {
  return await axios.put<INotificationPayload, INotificationResponse>(
    "/notification",
    props
  );
}

async function deleteNotificationApi(
  props: INotificationPayload
): Promise<INotificationResponse> {
  return await axios.delete<INotificationPayload, INotificationResponse>(
    "/notification",
    props
  );
}

async function getNotificationApi(
  params: Partial<INotification>
): Promise<INotificationResponse> {
  return await axios.get<INotificationPayload, INotificationResponse>(
    "/notification",
    {
      params,
    }
  );
}

async function searchNotificationsApi(
  params: ISearchNotificationsPayload
): Promise<ISearchNotificationsResponse> {
  return await axios.get<
    ISearchNotificationsPayload,
    ISearchNotificationsResponse
  >("/notification/search", {
    params,
  });
}

async function updateNotificationApi(
  props: INotificationPayload
): Promise<INotificationResponse> {
  return await axios.post<INotificationPayload, INotificationResponse>(
    "/notification",
    props
  );
}

/** Notification Channel (Parent) */
async function createNotificationChannelApi(
  props: INotificationChannelPayload
): Promise<INotificationChannelResponse> {
  return await axios.put<
    INotificationChannelPayload,
    INotificationChannelResponse
  >("/notification/channel", props);
}

async function deleteNotificationChannelApi(
  props: INotificationChannelPayload
): Promise<INotificationChannelResponse> {
  return await axios.delete<
    INotificationChannelPayload,
    INotificationChannelResponse
  >("/notification/channel", props);
}

async function getNotificationChannelApi(
  params: INotificationChannel
): Promise<INotificationChannelResponse> {
  return await axios.get<
    INotificationChannelPayload,
    INotificationChannelResponse
  >("/notification/channel", {
    params,
  });
}

async function searchNotificationChannelsApi(
  params: ISearchNotificationChannelsPayload
): Promise<ISearchNotificationChannelsResponse> {
  return await axios.get<
    ISearchNotificationChannelsPayload,
    ISearchNotificationChannelsResponse
  >("/notification/channel/search", {
    params,
  });
}

async function updateNotificationChannelApi(
  props: INotificationChannelPayload
): Promise<INotificationChannelResponse> {
  return await axios.post<
    INotificationChannelPayload,
    INotificationChannelResponse
  >("/notification/channel", props);
}

async function updateChannelApi(
  props: INotificationChannelPayload
): Promise<INotificationChannelResponse> {
  return await axios.post<
    INotificationChannelPayload,
    INotificationChannelResponse
  >("/notification/channel", props);
}

const notification = {
  create: {
    channel: createNotificationChannelApi,
    notification: createNotificationApi,
  },
  delete: {
    channel: deleteNotificationChannelApi,
    notification: deleteNotificationApi,
  },
  get: {
    channel: getNotificationChannelApi,
    notification: getNotificationApi,
  },
  search: {
    channel: searchNotificationChannelsApi,
    notifications: searchNotificationsApi,
  },
  update: {
    channel: updateNotificationChannelApi,
    notification: updateNotificationApi,
  },
};

export default notification;
