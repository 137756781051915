import { useEffect, useState } from "react";

/** Vendors */
import { useNavigate } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Flex, Layout, Menu } from "antd";

/** Custom Hooks */
import useDevice from "../../../hooks/useDevice";

/** Custom Theme */
import SectionalConfig from "../../../dist/theme/Config";

/** Custom Logo */
import Tradesmin from "@dist/img/logo/tradesmin-logo-text-light.png";

/** Custom CSS */
import "./navbar.css";

function RegisterNavbar() {
  return (
    <Layout.Header style={{ height: 100 }}>
      <div className="flex flex-column flex-column-center h-100">
        <img className="trades-logo" src={Tradesmin} />
      </div>
    </Layout.Header>
  );
}

export default RegisterNavbar;
