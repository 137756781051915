/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

const vendor = createReducer(initialState.vendor, (builder) => {
  builder
    .addCase(createAction(types.SEARCH_VENDORS_SUCCESS), (state, action) => ({
      ...state,
      list: action.results || [],
    }))
    .addDefaultCase((state) => state);
});

export default vendor;
