function TradesminBrand() {
  return (
    <span>
      <span>Trades</span>
      <span style={{ fontWeight: 300 }}>min</span>
    </span>
  );
}

export default TradesminBrand;
