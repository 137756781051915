/** Vendors */
import { Link } from "react-router-dom";
import { Col, Divider, Layout, Row, Space, Typography } from "antd";

/** Custom Theme */
import SectionalConfig from "../../../dist/theme/Config";

/** Custom Logo */
import Tradesmin from "@dist/img/logo/tradesmin-logo-text-light.png";

function Footer() {
  return (
    <Layout.Footer className="pl-4 pr-4">
      <div className="inner-section">
        <Row gutter={[24, 24]}>
          <Col className="flex flex-column" sm={{ span: 11 }} xs={24}>
            <Typography.Title level={4}>
              <img className="trades-logo" src={Tradesmin} />
            </Typography.Title>
            <Typography.Text type="secondary">
              Tradesmin provides a specialized digital solution for construction
              contractors. Our service offers tailored website templates for
              various trades like electricians and plumbers, integrated with
              essential tools like CRM and invoicing. This unique combination
              ensures a seamless online experience that mirrors your
              professional expertise, streamlining business management and
              enhancing your digital footprint.
            </Typography.Text>
          </Col>
          <Col sm={4} xs={24}>
            <Space className="w-100" direction="vertical">
              <Typography.Title level={4}>Terms</Typography.Title>
              <Link to="/legal/privacy">
                <Typography.Text>Privacy Policy</Typography.Text>
              </Link>
              <Link to="/legal/terms">
                <Typography.Text>Terms & Conditions</Typography.Text>
              </Link>
              <Link to="/legal/disclaimer">
                <Typography.Text>Disclaimer</Typography.Text>
              </Link>
            </Space>
          </Col>
          <Col sm={4} xs={24}>
            <Space className="w-100" direction="vertical">
              <Typography.Title level={4}>Reach Out</Typography.Title>
              <Link to="/contact">
                <Typography.Text>Contact Us</Typography.Text>
              </Link>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[24, 24]}>
          <Col align="center" flex={1}>
            <div>{new Date().getFullYear()} Copyright. Blue Umbrella LLC</div>
          </Col>
        </Row>
      </div>
    </Layout.Footer>
  );
}

export default Footer;
