/** Vendors */
import axios from "axios";

/** Types */
import type {
  IVendor,
  ISearchVendorsPayload,
  ISearchVendorsResponse,
} from "../types";

/* Vendors API Endpoints */
export async function searchVendorsApi(
  params: ISearchVendorsPayload
): Promise<ISearchVendorsResponse> {
  return await axios.get<ISearchVendorsPayload, ISearchVendorsResponse>(
    "/vendor/search",
    {
      params,
    }
  );
}

const vendor = {
  search: {
    vendors: searchVendorsApi,
  },
};

export default vendor;
