/** Vendors */
import axios from "axios";

/** Types */
import type {
  IProject,
  IProjectPayload,
  IProjectResponse,
  ISearchProjectsPayload,
  ISearchProjectsResponse,
  /** Project Event(s) */
  IProjectEvent,
  IProjectEventPayload,
  IProjectEventResponse,
  ISearchProjectEventsPayload,
  ISearchProjectEventsResponse,
} from "../types";

/* Project API Endpoints */
export async function createProjectApi(
  props: Partial<IProject>
): Promise<IProjectResponse> {
  return await axios.put<IProjectPayload, IProjectResponse>("/project", props);
}

export async function deleteProjectApi(
  props: IProjectPayload
): Promise<IProjectResponse> {
  return await axios.delete<IProjectPayload, IProjectResponse>(
    "/project",
    props
  );
}

export async function getProjectApi(
  params: Partial<IProject>
): Promise<IProjectResponse> {
  return await axios.get<IProjectPayload, IProjectResponse>("/project", {
    params,
  });
}

export async function searchProjectsApi(
  params: ISearchProjectsPayload
): Promise<ISearchProjectsResponse> {
  return await axios.get<ISearchProjectsPayload, ISearchProjectsResponse>(
    "/project/search",
    {
      params,
    }
  );
}

export async function updateProjectApi(
  props: IProjectPayload
): Promise<IProjectResponse> {
  return await axios.post<IProjectPayload, IProjectResponse>("/project", props);
}

/** Project Event API(s) */
export async function createProjectEventApi(
  props: Partial<IProjectEvent>
): Promise<IProjectEventResponse> {
  return await axios.put<IProjectEventPayload, IProjectEventResponse>(
    "/project/event",
    props
  );
}

export async function deleteProjectEventApi(
  props: IProjectEventPayload
): Promise<IProjectEventResponse> {
  return await axios.delete<IProjectEventPayload, IProjectEventResponse>(
    "/project/event",
    props
  );
}

export async function getProjectEventApi(
  params: Partial<IProjectEvent>
): Promise<IProjectEventResponse> {
  return await axios.get<IProjectEventPayload, IProjectEventResponse>(
    "/project/event",
    {
      params,
    }
  );
}

export async function searchProjectEventsApi(
  params: ISearchProjectEventsPayload
): Promise<ISearchProjectEventsResponse> {
  return await axios.get<
    ISearchProjectEventsPayload,
    ISearchProjectEventsResponse
  >("/project/event/search", {
    params,
  });
}

export async function updateProjectEventApi(
  props: IProjectEventPayload
): Promise<IProjectEventResponse> {
  return await axios.post<IProjectEventPayload, IProjectEventResponse>(
    "/project/event",
    {
      data: props,
    }
  );
}

const project = {
  create: {
    event: createProjectEventApi,
    project: createProjectApi,
  },
  delete: {
    event: deleteProjectEventApi,
    project: deleteProjectApi,
  },
  get: {
    event: getProjectEventApi,
    project: getProjectApi,
  },
  search: {
    events: searchProjectEventsApi,
    projects: searchProjectsApi,
  },
  update: {
    event: updateProjectEventApi,
    project: updateProjectApi,
  },
};

export default project;
