/** Fontawesome */
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faClockEight,
  faCircleEnvelope,
  faCirclePhone,
  faEarthAmericas,
} from "@fortawesome/sharp-regular-svg-icons";

library.add(faClockEight, faCircleEnvelope, faCirclePhone, faEarthAmericas);
