import React, { Suspense, lazy } from "react";

/** Vendors */
import { Navigate, createBrowserRouter } from "react-router-dom";

/** Layouts */
// import BuilderLayout from "../components/shared/layout/Builder";
// import MarketingLayout from "../components/shared/layout/Marketing";
// import ProfileLayout from "../components/shared/layout/Profile";
import RegisterLayout from "../components/shared/layout/Register";
/** Custom Components */
// import Home from "../components/pages/views/Home";
import Register from "../components/pages/views/Register";
// const Account = lazy(() => import("../components/pages/views/Account"));
// const Contact = lazy(() => import("../components/pages/views/Contact"));
// const Legal = lazy(() => import("../components/pages/views/Legal"));
// const Pricing = lazy(() => import("../components/pages/views/Pricing"));
// const Builder = lazy(() => import("../components/builder/views/Builder"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RegisterLayout />,
    children: [
      {
        index: true,
        element: <Register />,
      },
      {
        element: <Navigate to="/" replace />,
        path: "/*",
      },
    ],
  },
]);

export default router;

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <MarketingLayout />,
//     children: [
//       {
//         index: true,
//         lazy: async function () {
//           const Component = await import("../components/pages/views/Home");
//           return { Component: Component.default };
//         },
//       },
//       {
//         lazy: async function () {
//           const Component = await import("../components/pages/views/Account");
//           return { Component: Component.default };
//         },
//         path: "account",
//       },
//       {
//         lazy: async function () {
//           const Component = await import("../components/pages/views/Contact");
//           return { Component: Component.default };
//         },
//         path: "contact",
//       },
//       {
//         lazy: async function () {
//           const Component = await import("../components/pages/views/Legal");
//           return { Component: Component.default };
//         },
//         path: "legal",
//       },
//       {
//         lazy: async function () {
//           const Component = await import("../components/pages/views/Pricing");
//           return { Component: Component.default };
//         },
//         path: "pricing",
//       },
//     ],
//   },
//   {
//     path: "profile",
//     element: <ProfileLayout />,
//     children: [
//       {
//         index: true,
//         lazy: async function () {
//           const Component = await import("../components/profile/views/Profile");
//           return { Component: Component.default };
//         },
//       },
//       {
//         lazy: async function () {
//           const Component = await import(
//             "../components/profile/views/Customers"
//           );
//           return { Component: Component.default };
//         },
//         path: "customers",
//       },
//       {
//         lazy: async function () {
//           const Component = await import(
//             "../components/profile/views/Projects"
//           );
//           return { Component: Component.default };
//         },
//         path: "projects",
//       },
//       {
//         lazy: async function () {
//           const Component = await import("../components/profile/views/Project");
//           return { Component: Component.default };
//         },
//         path: "projects/:resource_name",
//       },
//     ],
//   },
//   {
//     path: "/:location",
//     element: <BuilderLayout />,
//     children: [
//       {
//         lazy: async function () {
//           const Component = await import("../components/builder/views/Builder");
//           return { Component: Component.default };
//         },
//         path: "setup",
//       },
//       {
//         lazy: async function () {
//           const Component = await import("../components/builder/views/Builder");
//           return { Component: Component.default };
//         },
//         path: "setup/:step",
//       },
//     ],
//   },
// ]);

// export default router;
