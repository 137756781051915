/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { FileStatus } from "../../types";

/** File Actions */
const file = createReducer(initialState.file, (builder) => {
  builder
    .addCase(createAction(types.DELETE_FILE_SUCCESS), (state, action) => {
      return redux.removeMatchCase(state, action, "file", "key");
    })
    .addCase(createAction(types.RESET_FILES_SUCCESS), (state) => ({
      ...state,
      list: [],
    }))
    .addCase(createAction(types.SEARCH_FILES_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_FILE_SUCCESS), (state, action) => {
      return redux.updateMatchCase(state, action, "file", "key");
    })
    .addDefaultCase((state) => state);
});

export default file;
