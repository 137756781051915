/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

const toggle = createReducer(initialState.toggle, (builder) => {
  builder
    .addCase(
      createAction(types.TOGGLE_ADVANCED_THEME_MODAL),
      (state, action) => ({ ...state, advancedTheme: !state.advancedTheme })
    )
    .addCase(createAction(types.TOGGLE_CUSTOMER_MODAL), (state, action) => ({
      ...state,
      customerModal: !state.customerModal,
    }))
    .addDefaultCase((state) => state);
});

export default toggle;
