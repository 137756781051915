/** Vendors */
import { Layout } from "antd";
import { RouterProvider } from "react-router-dom";

/** Custom Themes */
import AppThemeConfig from "./dist/theme/Config";

/** Router Paths */
import router from "./router/index";

function App() {
  return (
    <AppThemeConfig version={6}>
      <Layout className="tradesmin-layout">
        <Layout.Content>
          <RouterProvider router={router} />
        </Layout.Content>
      </Layout>
    </AppThemeConfig>
  );
}

export default App;
