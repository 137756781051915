/** Vendors */
import axios from "axios";

/** Types */
import type {
  IMember,
  IMemberPayload,
  IMemberResponse,
  ISearchMembersPayload,
  ISearchMembersResponse,
} from "../types";

/* Member API Endpoints */
export async function createMemberApi(
  props: Partial<IMember>
): Promise<IMemberResponse> {
  return await axios.put<IMemberPayload, IMemberResponse>("/member", props);
}

export async function deleteMemberApi(
  props: IMemberPayload
): Promise<IMemberResponse> {
  return await axios.delete<IMemberPayload, IMemberResponse>("/member", {
    data: props,
  });
}

export async function getMemberApi(
  params: Partial<IMember>
): Promise<IMemberResponse> {
  return await axios.get<IMemberPayload, IMemberResponse>("/member", {
    params,
  });
}

export async function contactMemberApi(
  props: Partial<IMember>
): Promise<IMemberResponse> {
  return await axios.put<IMemberPayload, IMemberResponse>(
    "/member/contact",
    props
  );
}

export async function registerMemberApi(
  props: Partial<IMember>
): Promise<IMemberResponse> {
  return await axios.put<IMemberPayload, IMemberResponse>(
    "/member/register",
    props
  );
}

export async function searchMembersApi(
  params: ISearchMembersPayload
): Promise<ISearchMembersResponse> {
  return await axios.get<ISearchMembersPayload, ISearchMembersResponse>(
    "/member/search",
    {
      params,
    }
  );
}

export async function updateMemberApi(
  props: IMemberPayload
): Promise<IMemberResponse> {
  return await axios.post<IMemberPayload, IMemberResponse>("/member", {
    data: props,
  });
}

const member = {
  contact: {
    member: contactMemberApi,
  },
  create: {
    member: createMemberApi,
  },
  delete: {
    member: deleteMemberApi,
  },
  get: {
    member: getMemberApi,
  },
  register: {
    member: registerMemberApi,
  },
  search: {
    members: searchMembersApi,
  },
  update: {
    member: updateMemberApi,
  },
};

export default member;
