import { useEffect, useState } from "react";

/** Vendors */
import {
  Button,
  Col,
  Form,
  Input,
  Flex,
  Row,
  Typography,
  notification,
} from "antd";

/** Redux */
import { registerMemberAction } from "@redux/actions/member";

/** Custom Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom Components */
import Icon from "../../shared/cards/Icon";
import TradesminBrand from "../../shared/cards/Brand";

/** Types */
import type { IRootState } from "@redux/configureStore";

function RegisterView() {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const { business, registered } = useAppSelector((state: IRootState) => ({
    business: state.business.details,
    registered: state.member.registered,
  }));

  useEffect(() => {
    setLoading(false);
    if (registered !== "unknown") {
      if (registered === "success") {
        api.success({
          description:
            "You have successfully registered for Tradesmin and will be notified via email once we launch.",
          message: "Registration Complete!",
        });
      } else if (registered === "failed") {
        api.info({
          description:
            "Seems we encountered an issue registering you. You either already have registered or we are in the middle of development updates. Please try again in a little bit.",
          message: "Registration Unsuccessful",
        });
      }
      form.resetFields();
    }
  }, [registered]);

  const actions = {
    onFinish: (values: any) => {
      setLoading(true);
      dispatch(registerMemberAction(values));
    },
  };

  return (
    <Flex
      align="center"
      className="bg-color-6 w-100"
      gap={96}
      style={{ minHeight: "calc(100vh - 200px)", marginTop: "10vh" }}
      vertical
    >
      {contextHolder}
      <Flex align="center" vertical>
        <Typography.Title className="m-0 text-center" level={1}>
          The Future of
        </Typography.Title>
        <Typography.Title className="m-0 text-center" level={1}>
          Hyper Personalized Construction Websites
        </Typography.Title>
      </Flex>
      <Row align="start" className="w-100" gutter={[12, 12]} justify="center">
        <Col
          className="w-100"
          lg={10}
          md={{ order: 1, span: 22 }}
          xs={{ order: 2, span: 22 }}
        >
          <Flex vertical gap={36}>
            <Typography.Title className="m-0" level={2}>
              Contact Us
            </Typography.Title>
            <Flex align="center" gap={8}>
              <Icon
                color="rgba(255, 255, 255, 0.45)"
                name="fa-earth-americas"
                size="2xl"
              />
              <Typography.Paragraph className="m-0" type="secondary">
                Location: San Diego, CA
              </Typography.Paragraph>
            </Flex>

            <Flex align="center" gap={8}>
              <Icon
                color="rgba(255, 255, 255, 0.45)"
                name="fa-circle-envelope"
                size="2xl"
              />
              <Typography.Paragraph className="m-0" type="secondary">
                Email: support@blueumbrella.consulting
              </Typography.Paragraph>
            </Flex>
            <Flex align="center" gap={8}>
              <Icon
                color="rgba(255, 255, 255, 0.45)"
                name="fa-circle-phone"
                size="2xl"
              />
              <Typography.Paragraph className="m-0" type="secondary">
                Call Us: (501) 232-6326
              </Typography.Paragraph>
            </Flex>
            <Flex align="center" gap={8}>
              <Icon
                color="rgba(255, 255, 255, 0.45)"
                name="fa-clock-eight"
                size="2xl"
              />
              <Typography.Paragraph className="m-0" type="secondary">
                Office Hours: Mon-Sun, 8am to 7pm pst
              </Typography.Paragraph>
            </Flex>
          </Flex>
        </Col>
        <Col
          lg={{ span: 10, offset: 1 }}
          md={{ order: 2, span: 22 }}
          xs={{ order: 1, span: 22 }}
        >
          <Typography.Title
            className={`mt-0 ${
              window.innerWidth < 585 ? "text-center" : "text-right"
            }`}
            level={2}
          >
            Pre Register for <TradesminBrand />
          </Typography.Title>
          <Form
            className="w-100"
            form={form}
            labelCol={{ span: 10 }}
            layout="horizontal"
            onFinish={actions.onFinish}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item
              label="First Name"
              name="given_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="John" size="large" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="family_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Smith" size="large" />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
              <Input placeholder="john.smith@gmail.com" size="large" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input placeholder="(XXX) XXX-XXXX" size="large" />
            </Form.Item>
            <Flex justify="flex-end">
              <Button
                htmlType="submit"
                loading={loading}
                size="large"
                type="primary"
              >
                Join Waitlist
              </Button>
            </Flex>
          </Form>
        </Col>
      </Row>
    </Flex>
  );
}

RegisterView.displayName = "Registration View";

export default RegisterView;
