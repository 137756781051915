/** Vendors */
import axios from "axios";

/** Types */
import type {
  IBusiness,
  IBusinessPayload,
  IBusinessResponse,
  ISearchBusinessesPayload,
  ISearchBusinessesResponse,
} from "../types";

/* Business API Endpoints */
export async function createBusinessApi(
  props: Partial<IBusiness>
): Promise<IBusinessResponse> {
  return await axios.put<IBusinessPayload, IBusinessResponse>(
    "/business",
    props
  );
}

export async function deleteBusinessApi(
  props: IBusinessPayload
): Promise<IBusinessResponse> {
  return await axios.delete<IBusinessPayload, IBusinessResponse>("/business", {
    data: props,
  });
}

export async function getBusinessApi(
  params: Partial<IBusiness>
): Promise<IBusinessResponse> {
  return await axios.get<IBusinessPayload, IBusinessResponse>("/business", {
    params,
  });
}

export async function searchBusinesssApi(
  params: ISearchBusinessesPayload
): Promise<ISearchBusinessesResponse> {
  return await axios.get<ISearchBusinessesPayload, ISearchBusinessesResponse>(
    "/business/search",
    {
      params,
    }
  );
}

export async function updateBusinessApi(
  props: IBusinessPayload
): Promise<IBusinessResponse> {
  return await axios.post<IBusinessPayload, IBusinessResponse>("/business", {
    data: props,
  });
}

const business = {
  create: {
    business: createBusinessApi,
  },
  delete: {
    business: deleteBusinessApi,
  },
  get: {
    business: getBusinessApi,
  },
  search: {
    businesss: searchBusinesssApi,
  },
  update: {
    business: updateBusinessApi,
  },
};

export default business;
