/** Redux */
import { IAppDispatch, IGetState } from "../configureStore";
import * as types from "./types";

/** Types */
import type { IMember, ISearchMembers } from "../../types";

export function createMemberAction(member: IMember) {
  return async function (
    dispatch: IAppDispatch,
    _getState: IGetState,
    api: any
  ) {
    const response = await api.member.create.member({ member });
    dispatch({
      ...response,
      type: types.CREATE_MEMBER_SUCCESS,
    });
  };
}

export function deleteMemberAction(member: IMember) {
  return async function (
    dispatch: IAppDispatch,
    _getState: IGetState,
    api: any
  ) {
    const response = await api.member.delete.member({ member });
    dispatch({
      ...response,
      type: types.DELETE_MEMBER_SUCCESS,
    });
  };
}

export function getMemberAction(member: IMember) {
  return async function (
    dispatch: IAppDispatch,
    _getState: IGetState,
    api: any
  ) {
    const response = await api.member.get.member({ member });
    dispatch({
      ...response,
      type: types.GET_MEMBER_SUCCESS,
    });
  };
}

export function registerMemberAction(member) {
  return async function (
    dispatch: IAppDispatch,
    _getState: IGetState,
    api: any
  ) {
    const response = await api.member.register.member({ member });
    dispatch({
      ...response,
      type: types.REGISTER_MEMBER_SUCCESS,
    });
  };
}

export function searchMembersAction(search: ISearchMembers) {
  return async function (
    dispatch: IAppDispatch,
    _getState: IGetState,
    api: any
  ) {
    const response = await api.member.search.members(search);
    dispatch({
      ...response,
      type: types.SEARCH_MEMBERS_SUCCESS,
    });
  };
}

export function updateMemberAction(member: IMember) {
  return async function (
    dispatch: IAppDispatch,
    _getState: IGetState,
    api: any
  ) {
    const response = await api.member.update.member({ member });
    dispatch({
      ...response,
      type: types.UPDATE_MEMBER_SUCCESS,
    });
  };
}
