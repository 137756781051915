/** Business Action(s) */
export const CREATE_BUSINESS_SUCCESS = "CREATE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS";
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS";
export const SEARCH_BUSINESSES_SUCCESS = "SEARCH_BUSINESSES_SUCCESS";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";

/** Customer Actions */
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const SEARCH_CUSTOMERS_SUCCESS = "SEARCH_CUSTOMERS_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";

/** File Action(s) */
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const RESET_FILES_SUCCESS = "RESET_FILES_SUCCESS";
export const SEARCH_FILES_SUCCESS = "SEARCH_FILES_SUCCESS";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";

/** Member Action(s) */
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const REGISTER_MEMBER_SUCCESS = "REGISTER_MEMBER_SUCCESS";
export const SEARCH_MEMBERS_SUCCESS = "SEARCH_MEMBERS_SUCCESS";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";

/** Notification Action(s) */
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const SEARCH_NOTIFICATIONS_SUCCESS = "SEARCH_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

/** Notification Channel Action(s) */
export const CREATE_NOTIFICATION_CHANNEL_SUCCESS =
  "CREATE_NOTIFICATION_CHANNEL_SUCCESS";
export const DELETE_NOTIFICATION_CHANNEL_SUCCESS =
  "DELETE_NOTIFICATION_CHANNEL_SUCCESS";
export const GET_NOTIFICATION_CHANNEL_SUCCESS =
  "GET_NOTIFICATION_CHANNEL_SUCCESS";
export const SEARCH_NOTIFICATION_CHANNELS_SUCCESS =
  "SEARCH_NOTIFICATION_CHANNELS_SUCCESS";
export const UPDATE_NOTIFICATION_CHANNEL_SUCCESS =
  "UPDATE_NOTIFICATION_CHANNEL_SUCCESS";

/** Project Action(s) */
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const SEARCH_PROJECTS_SUCCESS = "SEARCH_PROJECTS_SUCCESS";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

/** Project Event Timeline */
export const CREATE_PROJECT_EVENT_SUCCESS = "CREATE_PROJECT_EVENT_SUCCESS";
export const DELETE_PROJECT_EVENT_SUCCESS = "DELETE_PROJECT_EVENT_SUCCESS";
export const GET_PROJECT_EVENT_SUCCESS = "GET_PROJECT_EVENT_SUCCESS";
export const SEARCH_PROJECT_EVENTS_SUCCESS = "SEARCH_PROJECT_EVENTS_SUCCESS";
export const UPDATE_PROJECT_EVENT_SUCCESS = "UPDATE_PROJECT_EVENT_SUCCESS";

/** Review Actions */
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const SEARCH_REVIEWS_SUCCESS = "SEARCH_REVIEWS_SUCCESS";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";

/* Socket Middleware Action */
export const ESTABLISH_SOCKET_CONNECTION = "ESTABLISH_SOCKET_CONNECTION";
export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED";
export const SOCKET_SEND_MESSAGE = "SOCKET_SEND_MESSAGE";

/** Template Actions */
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";

/** Toggle Stuff */
export const TOGGLE_ADVANCED_THEME_MODAL = "TOGGLE_ADVANCED_THEME_MODAL";
export const TOGGLE_CUSTOMER_MODAL = "TOGGLE_CUSTOMER_MODAL";
export const TOGGLE_NOTIFICATION_SUCCESS = "TOGGLE_NOTIFICATION_SUCCESS";

/** Vendor Actions */
export const SEARCH_VENDORS_SUCCESS = "SEARCH_VENDORS_SUCCESS";
