/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "@dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const project = createReducer(initialState.project, (builder) => {
  /** Projects */
  builder
    .addCase(
      createAction(types.CREATE_PROJECT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) =>
        redux.addResource(state, action, ResourceId.Project)
    )
    .addCase(
      createAction(types.DELETE_PROJECT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) =>
        redux.removeMatchCase(state, action, ResourceId.Project)
    )
    .addCase(
      createAction(types.GET_PROJECT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) => ({
        ...state,
        details: action.project,
      })
    )
    .addCase(
      createAction(types.SEARCH_PROJECTS_SUCCESS),
      (state: IRootState["project"], action: AnyAction) =>
        redux.joinOrOverwrite(state, action, ResourceId.Project)
    )
    .addCase(
      createAction(types.UPDATE_PROJECT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) =>
        redux.updateMatchCase(state, action, ResourceId.Project)
    );

  /** Project Events */
  builder
    .addCase(
      createAction(types.CREATE_PROJECT_EVENT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) => ({
        ...state,
        event: redux.addResource(state.event, action, ResourceId.ProjectEvent),
      })
    )
    .addCase(
      createAction(types.DELETE_PROJECT_EVENT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) => ({
        ...state,
        event: redux.removeMatchCase(
          state.event,
          action,
          ResourceId.ProjectEvent
        ),
      })
    )
    .addCase(
      createAction(types.GET_PROJECT_EVENT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) => ({
        ...state,
        event: { ...state.event, details: action.event },
      })
    )
    .addCase(
      createAction(types.SEARCH_PROJECT_EVENTS_SUCCESS),
      (state: IRootState["project"], action: AnyAction) => ({
        ...state,
        event: redux.joinOrOverwrite(
          state.event,
          action,
          ResourceId.ProjectEvent
        ),
      })
    )
    .addCase(
      createAction(types.UPDATE_PROJECT_EVENT_SUCCESS),
      (state: IRootState["project"], action: AnyAction) => ({
        ...state,
        event: redux.updateMatchCase(
          state.event,
          action,
          ResourceId.ProjectEvent
        ),
      })
    )
    .addDefaultCase((state) => state);
});

export default project;
