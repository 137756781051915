/** Enums */
import {
  NotificationStatus,
  ProjectEventStatus,
  ResourceId,
} from "../../types";

/** Types */
import type {
  IBusiness,
  ICustomer,
  IFile,
  IMember,
  INotification,
  INotificationChannel,
  IProject,
  IProjectEvent,
  IReview,
  IStandardList,
  ITemplate,
  IVendor,
} from "../../types";

export interface IInitialState {
  business: IStandardList<IBusiness>;
  customer: IStandardList<ICustomer>;
  file: IStandardList<IFile>;
  member: IStandardList<IMember> & { registered: string };
  notification: IStandardList<INotification> & {
    channel: IStandardList<INotificationChannel>;
  };
  project: IStandardList<IProject> & { event: IStandardList<IProjectEvent> };
  review: IStandardList<IReview[]>;
  socket: { status: "connected" | "disconnected" | string };
  template: ITemplate;
  toggle: { [key: string]: boolean };
  vendor: IStandardList<IVendor>;
}

const initialState: IInitialState = {
  business: {
    details: {} as IBusiness,
    list: [
      {
        name: "Finner Electric",
        owner: "member-123",
        resource_id: "business",
        resource_name: "business-123",
      },
      {
        name: "Malta Electric",
        owner: "member-456",
        resource_id: "business",
        resource_name: "business-456",
      },
    ],
  },
  customer: {
    details: {
      email: "adam.kramer@anbro.io",
      given_name: "Adam",
      family_name: "Kramer 1",
      resource_id: "member",
      resource_name: "member-123",
    } as ICustomer,
    list: [
      {
        email: "adam.kramer@anbro.io",
        given_name: "Adam",
        family_name: "Kramer 1",
        resource_id: "member",
        resource_name: "member-123",
      },
      {
        cellphone: "+1 (716) 713-5353",
        email: "adam.kramer@blueumbrella.consulting",
        given_name: "Adam",
        family_name: "Kramer 2",
        resource_id: "member",
        resource_name: "member-456",
      },
    ],
  },
  file: {
    details: {} as IFile,
    list: [
      {
        key: "picture-1.webp",
        bucket_name: "alibaba",
        prefix: "assets/img",
        metadata: {},
        tags: {},
        url: "https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp",
      },
      {
        key: "picture-2.webp",
        bucket_name: "alibaba",
        prefix: "assets/img",
        metadata: {},
        tags: {},
        url: "https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp",
      },
      {
        key: "picture-3.webp",
        bucket_name: "alibaba",
        prefix: "assets/img",
        metadata: {},
        tags: {},
        url: "https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp",
      },
    ],
  },
  member: {
    details: {
      family_name: "Kramer",
      given_name: "Adam",
      picture:
        "https://c8.alamy.com/comp/2J3B2T7/3d-illustration-of-smiling-businessman-close-up-portrait-cute-cartoon-man-avatar-character-face-isolated-on-white-background-2J3B2T7.jpg",
      resource_id: "member",
      resource_name: "member-123",
    },
    list: [],
    registered: "unknown",
  },
  notification: {
    channel: {
      details: {} as INotificationChannel,
      list: [
        {
          created: new Date().getTime(),
          name: "Channel 1",
          parent: "project-123",
          resource_id: "channel",
          resource_name: "channel-123",
          timestamp: new Date().getTime(),
          updated: new Date().getTime(),
        },
      ],
    },
    details: {} as INotification,
    list: [
      {
        created: new Date().getTime() - 1000 * 3600 * 24 * 2,
        data: JSON.stringify({
          notification: { text: "<div>Something</div>", type: "enhanced" },
        }),
        member: "member-123",
        resource_id: ResourceId.ProjectEvent,
        resource_name: "project-event-123",
        status: NotificationStatus.Unsent,
        template: "channel-notification",
        timestamp: new Date().getTime() - 1000 * 3600 * 24 * 2,
        updated: new Date().getTime(),
      },
      {
        created: new Date().getTime() - 1000 * 3600 * 24,
        data: JSON.stringify({
          notification: { text: "Something Else", type: "text" },
        }),
        member: "member-456",
        resource_id: ResourceId.ProjectEvent,
        resource_name: "project-event-456",
        status: NotificationStatus.Sent,
        template: "channel-notification",
        updated: new Date().getTime() - 1000 * 3600 * 24,
        timestamp: new Date().getTime(),
      },
    ],
  },
  project: {
    details: {} as IProject,
    event: {
      details: {} as IProjectEvent,
      list: [
        {
          created: new Date().getTime(),
          description: "Submit paperwork to SDGE for Panel Upgrade",
          end: new Date().getTime() - 1000 * 3600 * 24 * 4,
          resource_id: ResourceId.ProjectEvent,
          resource_name: "project-event-123",
          start: new Date().getTime() - 1000 * 3600 * 24 * 6,
          status: ProjectEventStatus.Done,
          title: "Submit Panel Upgrade Request",
          updated: new Date().getTime(),
          timestamp: new Date().getTime(),
        },
        {
          created: new Date().getTime(),
          description: "Submit paperwork to City for Approval",
          end: new Date().getTime() + 1000 * 3600 * 24 * 2,
          resource_id: ResourceId.ProjectEvent,
          resource_name: "project-event-456",
          start: new Date().getTime() - 1000 * 3600 * 24 * 1,
          status: ProjectEventStatus.OnHold,
          title: "Submit for City Permit",
          updated: new Date().getTime(),
          timestamp: new Date().getTime(),
        },
        {
          created: new Date().getTime(),
          description: "Perform the panel upgrade with SDGE removing meter",
          end: new Date().getTime() + 1000 * 3600 * 24 * 4,
          resource_id: ResourceId.ProjectEvent,
          resource_name: "project-event-789",
          start: new Date().getTime() + 1000 * 3600 * 24 * 5,
          status: ProjectEventStatus.InProgress,
          title: "Day of Panel Upgrade",
          updated: new Date().getTime(),
          timestamp: new Date().getTime(),
        },
        {
          created: new Date().getTime(),
          description: "Day of City Inspection and Approval",
          end: new Date().getTime() + 1000 * 3600 * 24 * 10,
          resource_id: ResourceId.ProjectEvent,
          resource_name: "project-event-098",
          start: new Date().getTime() + 1000 * 3600 * 24 * 12,
          status: ProjectEventStatus.Pending,
          title: "City Approval",
          updated: new Date().getTime(),
          timestamp: new Date().getTime(),
        },
      ],
    },
    list: [
      {
        address: "123 Main St",
        city: "Oceanside",
        description: "A brief description about this job",
        formatted_address: "123 Main St Oceanside CA 92056",
        name: "EV Charger",
        owner: "member-123",
        resource_id: "project",
        resource_name: "project-123",
        service: "service-123",
        state: "CA",
        zip_code: "92056",
      },
      {
        address: "456 Main St",
        city: "Carlsbad",
        description: "A super sweet description about this job",
        formatted_address: "456 Main St Oceanside CA 92056",
        name: "ESS Install",
        owner: "member-456",
        resource_id: "project",
        resource_name: "project-456",
        service: "service-456",
        state: "CA",
        zip_code: "92056",
      },
    ] as IProject[],
  },
  review: {
    details: {} as IReview,
    list: [
      {
        author: "member-123",
        description:
          "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu, Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Quis autem vel Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu, Ut enim ",
        resource_id: "review",
        resource_name: "review-123",
        title: "This site is awesome 1",
      },
      {
        author: "member-456",
        description:
          "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu, Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Quis autem vel Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu, Ut enim ",
        resource_id: "review",
        resource_name: "review-456",
        title: "This site is awesome 2",
      },
    ],
  },
  socket: { status: "disconnected" },
  template: {
    assets: { favicon: "", logo: "" },
    domain: { name: "", host: "" },
    services: {
      lighting: { enabled: true, html: "" },
      panel: { enabled: true, html: "" },
    },
    theme: "{}",
  },
  toggle: {
    advancedTheme: false,
  },
  vendor: {
    details: {} as IVendor,
    list: [
      {
        name: "Tesla EV Chargers",
        resource_id: "vendor",
        resource_name: "vendor-123",
        type: "charger",
      },
      {
        name: "Tesla Powerwall",
        resource_id: "vendor",
        resource_name: "vendor-456",
        type: "ess",
      },
      {
        name: "Enphase EV Charger",
        resource_id: "vendor",
        resource_name: "vendor-789",
        type: "charger",
      },
      {
        name: "Enphase IQ Battery",
        resource_id: "vendor",
        resource_name: "vendor-098",
        type: "ess",
      },
      {
        name: "ChargePoint EV Charger",
        resource_id: "vendor",
        resource_name: "vendor-765",
        type: "charger",
      },
      {
        name: "Anbro Energy Systems ESS",
        resource_id: "vendor",
        resource_name: "vendor-432",
        type: "ess",
      },
    ] as IVendor[],
  },
};

export default initialState;
