/** Vendors */
import _ from "lodash";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

const template = createReducer(initialState.template, (builder) => {
  builder
    .addCase(createAction(types.UPDATE_TEMPLATE_SUCCESS), (state, action) =>
      _.merge(state, action.template)
    )
    .addDefaultCase((state) => state);
});

export default template;
