/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const review = createReducer(initialState.review, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_REVIEW_SUCCESS),
      (state: IRootState["review"], action: AnyAction) => ({
        ...state,
        details: action.review,
      })
    )
    .addCase(
      createAction(types.DELETE_REVIEW_SUCCESS),
      (state: IRootState["review"], action: AnyAction) => ({
        ...state,
        details: action.review,
      })
    )
    .addCase(
      createAction(types.GET_REVIEW_SUCCESS),
      (state: IRootState["review"], action: AnyAction) => ({
        ...state,
        details: action.review,
      })
    )
    .addCase(
      createAction(types.SEARCH_REVIEWS_SUCCESS),
      (state: IRootState["review"], action: AnyAction) => ({
        ...state,
        list: action.results,
      })
    )
    .addCase(
      createAction(types.UPDATE_REVIEW_SUCCESS),
      (state: IRootState["review"], action: AnyAction) => ({
        ...state,
        details: action.review,
      })
    )
    .addDefaultCase((state) => state);
});

export default review;
