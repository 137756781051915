/** Vendors */
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

/** Blue Umbrella Standardized REST API Endpoint */
axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API_ENDPOINT;

/** Blue Umbrella App and Client Signatures */
axios.defaults.headers.common["x-bu-app"] =
  import.meta.env.VITE_REACT_APP_BU_APP_RESOURCE_NAME;
axios.defaults.headers.common["x-bu-client"] =
  import.meta.env.VITE_REACT_APP_BU_CLIENT_RESOURCE_NAME;

/** Standard Header Info */
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.response.use(
  function (response) {
    if (!Object.prototype.hasOwnProperty.call(response, "data")) {
      return response;
    }
    return response.data;
  },
  function (error) {
    console.log("Error Status Code: ", error?.response?.status, error?.message);
    return Promise.reject({ error: error.message });
  }
);

let analytics = {};
if (import.meta.env.MODE === "production") {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_REACT_APP_FCM_API_KEY,
    appId: import.meta.env.VITE_REACT_APP_FCM_APP_ID,
    authDomain: import.meta.env.VITE_REACT_APP_FCM_AUTH_DOMAIN,
    measurementId: import.meta.env.VITE_REACT_APP_FCM_MEASUREMENT_ID,
    messagingSenderId: import.meta.env.VITE_REACT_APP_FCM_MESSAGE_SENDER_ID,
    projectId: import.meta.env.VITE_REACT_APP_FCM_PROJECT_ID,
    storageBucket: import.meta.env.VITE_REACT_APP_FCM_STORAGE_BUCKET,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

export { analytics };
