import React from "react";

/** Vendors */
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

/** Custom Components */
import RegisterFooter from "../footer/Register";
import RegisterNavbar from "../navbar/Register";

function RegisterLayout() {
  return (
    <React.Fragment>
      <RegisterNavbar />
      <Outlet />
      <RegisterFooter />
    </React.Fragment>
  );
}

export default RegisterLayout;
