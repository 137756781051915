/** Vendors */
import { combineReducers } from "@reduxjs/toolkit";

/** Reducers */
import business from "./business";
import customer from "./customer";
import file from "./file";
import member from "./member";
import notification from "./notification";
import project from "./project";
import review from "./review";
import socket from "./socket";
import template from "./template";
import toggle from "./toggle";
import vendor from "./vendor";

const rootReducer = combineReducers({
  business,
  customer,
  file,
  member,
  notification,
  project,
  review,
  socket,
  template,
  toggle,
  vendor,
});

export default rootReducer;
