/**
 * @description FontAwesome 'Sharp' Icon Rendering
 */
/** Vendors */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/** Types */
export interface IIcon {
  color?: "inherit" | string;
  name: string;
  size?: "xs" | "md" | "lg" | "2x" | "2xl" | "3x" | "4x" | "5x" | "6x" | string;
  type?: "light" | "regular" | "solid";
}

function Icon({
  color = "inherit",
  name,
  size = "md",
  type = "regular",
}: IIcon) {
  return (
    <FontAwesomeIcon
      color={color}
      icon={`fa-sharp fa-${type} ${name}`}
      size={size}
    />
  );
}

export default Icon;
