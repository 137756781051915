/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const member = createReducer(initialState.member, (builder) => {
  /** Member Records */
  builder
    .addCase(
      createAction(types.CREATE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => ({
        ...state,
        details: action.member,
      })
    )
    .addCase(
      createAction(types.DELETE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => ({
        ...state,
        details: action.member,
      })
    )
    .addCase(
      createAction(types.GET_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => ({
        ...state,
        details: action.member,
      })
    )
    .addCase(
      createAction(types.SEARCH_MEMBERS_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => ({
        ...state,
        list: action.results,
      })
    )
    .addCase(
      createAction(types.UPDATE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => ({
        ...state,
        details: action.member,
      })
    );

  /** Registration Response */
  builder
    .addCase(
      createAction(types.REGISTER_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => ({
        ...state,
        registered: action.message,
      })
    )
    .addDefaultCase((state) => state);
});

export default member;
