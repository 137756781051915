/** Vendors */
import axios from "axios";

/** Types */
import type {
  IReview,
  IReviewPayload,
  IReviewResponse,
  ISearchReviewsPayload,
  ISearchReviewsResponse,
} from "../types";

/* Review API Endpoints */
export async function createReviewApi(
  props: Partial<IReview>
): Promise<IReviewResponse> {
  return await axios.put<IReviewPayload, IReviewResponse>("/review", props);
}

export async function deleteReviewApi(
  props: IReviewPayload
): Promise<IReviewResponse> {
  return await axios.delete<IReviewPayload, IReviewResponse>("/review", {
    data: props,
  });
}

export async function getReviewApi(
  params: Partial<IReview>
): Promise<IReviewResponse> {
  return await axios.get<IReviewPayload, IReviewResponse>("/review", {
    params,
  });
}

export async function searchReviewsApi(
  params: ISearchReviewsPayload
): Promise<ISearchReviewsResponse> {
  return await axios.get<ISearchReviewsPayload, ISearchReviewsResponse>(
    "/review/search",
    {
      params,
    }
  );
}

export async function updateReviewApi(
  props: IReviewPayload
): Promise<IReviewResponse> {
  return await axios.post<IReviewPayload, IReviewResponse>("/review", {
    data: props,
  });
}

const review = {
  create: {
    review: createReviewApi,
  },
  delete: {
    review: deleteReviewApi,
  },
  get: {
    review: getReviewApi,
  },
  search: {
    reviews: searchReviewsApi,
  },
  update: {
    review: updateReviewApi,
  },
};

export default review;
