/** Vendors */
import axios from "axios";

export async function deleteFileApi(file: IFile) {
  return await axios.delete("/file", { file });
}

export async function createFileApi(file: IFile) {
  return await axios.put("/file", { file });
}

export async function getFileApi(params: IFile) {
  return await axios.get("/file", { params });
}

export async function searchFilesApi(params: ISearchFiles) {
  return await axios.get("/file/search", { params });
}

export async function updateFileApi(file: IFile) {
  return await axios.post("/file", { file });
}

/** Step . Uploading directly to S3 with signed url */
export async function uploadFileApi({ content_type, file, url }) {
  const instance = axios.create();
  delete instance.defaults.headers.common["Authorization"];

  return await instance.create().put(url, file, {
    headers: {
      "Content-Type": contentType,
    },
  });
}

const file = {
  delete: {
    file: deleteFileApi,
  },
  get: {
    file: getFileApi,
  },
  search: {
    files: searchFilesApi,
  },
  update: {
    file: updateFileApi,
  },
  upload: {
    file: uploadFileApi,
  },
};

export default file;
